<template>
  <div v-if="activeTab == 1">
    <div class="span-12 medium-border-radius border-form gap2 mb-1 ph-2 pb-2 pt-2">
      <div class="flex mt-1">
        <h6 class="text-bold text-center text-md mb-2"> INFORMAÇÕES GERAIS</h6>
      </div>
      <div class="gap2 columns">
        <div class="span-2">
          <div class="flex ai-c jc-sb mb-1">
            <div class="flex ai-c text-light">
              Licença de Importação
            </div>
          </div>
          <Toggle v-model="process.importLicense" name="importLicense" :labels="{checked: 'Possui Licença', unchecked: 'Não Possui Licença',}" @input="() => inputUpdate('importLicense')"/>
        </div>

        <div class="span-4">
          <div class="flex ai-c jc-sb mb-1">
            <div class="flex ai-c text-light">
              Cliente
            </div>
          </div>
          <input v-model="process.customer.name" disabled placeholder='N/I' type="text"/>
        </div>

        <div class="span-2">
          <div class="flex ai-c jc-sb mb-1">
            <div class="flex ai-c text-light">
              Ref. Pedido / Cliente
            </div>
          </div>
          <input v-model="process.customerRef" disabled placeholder='N/I' type="text"/>
        </div>

        <div class="span-2">
          <div class="flex ai-c jc-sb mb-1">
            <div class="flex ai-c text-light">
              Exportador
            </div>
          </div>
          <input v-model="process.exporter.name" disabled placeholder='N/I' type="text"/>
        </div>

        <div class="span-2">
          <div class="flex ai-c jc-sb mb-1">
            <div class="flex ai-c text-light">
              Fabricante / Produtor
            </div>
          </div>
          <input v-model="process.manufacturer.name" disabled placeholder='N/I' type="text"/>
        </div>

        <div class="span-2">
          <div class="flex ai-c jc-sb mb-1">
            <div class="flex ai-c text-light">
              Invoice
            </div>
          </div>
          <input v-model="process.invoice" disabled placeholder='N/I' type="text"/>
        </div>

        <div class="span-2">
          <div class="flex ai-c jc-sb mb-1">
            <div class="flex ai-c text-light">
              Data Invoice
            </div>
          </div>
          <input :value="formatData(process.invoiceDate)" disabled placeholder='N/I'
                type="text"/>
        </div>

        <div class="span-2">
          <div class="flex ai-c jc-sb mb-1">
            <div class="flex ai-c text-light">
              Outras Referências
            </div>
          </div>
          <input v-model="process.anotherRef" disabled placeholder='N/I' type="text"/>
        </div>

        <div class="span-2">
          <div class="flex ai-c jc-sb mb-1">
            <div class="flex ai-c text-light">
              Local de Embarque
            </div>
          </div>
          <input v-model="process.placeOfLoading" disabled placeholder='N/I' type="text"/>
        </div>

        <div class="span-2">
          <div class="flex ai-c jc-sb mb-1">
            <div class="flex ai-c text-light">
              Referência Despachante
            </div>
          </div>
          <input v-model="process.customBrokerRef" :disabled="disableClick" type="text" @input="() => inputUpdate('customBrokerRef')"/></div>

      </div>
    </div>

    <div class="columns gap2">
      <div class="span-12 columns">
        <div class="span-12 medium-border-radius border-form gap2 mb-1 ph-2 pb-2 pt-2">
          <div class="flex mt-1">
            <h6 class="text-bold text-center text-md mb-2"> DATAS PREVISTAS </h6>
          </div>
          <div class="gap2 columns">

            <div class="span-2">
              <div class="flex ai-c jc-sb mb-1">
                <div class="flex ai-c text-light">
                  <CalendarAltIcon class="mr-1"/> Prontidão de Carga
                </div>
              </div>
              <DatePicker v-model="process.datesEstimatedGoodsReadinesDate" disabled placeholder='N/I'/>
            </div>

            <div class="span-2">
              <div class="flex ai-c jc-sb mb-1">
                <div class="flex ai-c text-light">
                  <CalendarAltIcon class="mr-1"/> ETD Inicial
                </div>
              </div>
              <DatePicker v-model="process.initialDatesETD" disabled placeholder='N/I'/>
            </div>

            <div class="span-2">
              <div class="flex ai-c jc-sb mb-1">
                <div class="flex ai-c text-light">
                  <CalendarAltIcon class="mr-1"/> ETD
                </div>
              </div>
              <DatePicker v-model="process.datesETD" disabled placeholder='N/I'/>
            </div>

            <div class="span-2">
              <div class="flex ai-c jc-sb mb-1">
                <div class="flex ai-c text-light">
                  <CalendarAltIcon class="mr-1"/> ETA
                </div>
              </div>
              <DatePicker v-model="process.datesETA" disabled placeholder='N/I'/>
            </div>

            <div class="span-2">
              <div class="flex ai-c jc-sb mb-1">
                <div class="flex ai-c text-light">
                  <CalendarAltIcon class="mr-1"/> Numerário
                </div>
              </div>
              <DatePicker v-model="process.estimatedStepCashRequest" disabled placeholder='N/I'/>
            </div>

            <div class="span-2">
              <div class="flex ai-c jc-sb mb-1">
                <div class="flex ai-c text-light">
                  <CalendarAltIcon class="mr-1"/> Entrega no Cliente
                </div>
              </div>
              <DatePicker v-model="process.datesEstimatedCustomerDeliverDate" disabled placeholder='N/I'/>
            </div>
          </div>
        </div>

        <div class="span-12 medium-border-radius border-form gap2 mb-1 ph-2 pb-2 pt-2">
          <div class="flex mt-1">
            <h6 class="text-bold text-center text-md mb-2"> DATAS CONFIRMADAS </h6>
          </div>
          <div class="gap2 columns">
            <div class="span-2">
              <div class="flex ai-c jc-sb mb-1">
                <div class="flex ai-c text-light">
                  <CalendarAltIcon class="mr-1"/> Prontidão de Carga
                </div>
              </div>
              <DatePicker v-model="process.datesGoodsReadinesDate" disabled placeholder='N/I'/>
            </div>

            <div class="span-2">
              <div class="flex ai-c jc-sb mb-1">
                <div class="flex ai-c text-light">
                  <CalendarAltIcon class="mr-1"/> Embarque
                </div>
              </div>
              <DatePicker v-model="process.datesDepartureDate" disabled placeholder='N/I'/>
            </div>

            <div class="span-2">
              <div class="flex ai-c jc-sb mb-1">
                <div class="flex ai-c text-light">
                  <CalendarAltIcon class="mr-1"/> Atracação
                </div>
              </div>
              <DatePicker v-model="process.mooringDate" disabled placeholder='N/I'/>
            </div>

            <div class="span-2">
              <div class="flex ai-c jc-sb mb-1">
                <div class="flex ai-c text-light">
                  <CalendarAltIcon class="mr-1"/> Chegada
                </div>
              </div>
              <DatePicker v-model="process.datesArrivalDate" disabled placeholder='N/I'/>
            </div>

            <div class="span-2">
              <div class="flex ai-c jc-sb mb-1">
                <div class="flex ai-c text-light">
                  <CalendarAltIcon class="mr-1"/> Presença
                </div>
              </div>
              <DatePicker v-model="process.datesGoodsPresenceDate" :disabled="disableClick" placeholder='' @input="() => inputUpdate('datesGoodsPresenceDate')"/>
            </div>

            <div class="span-2">
              <div class="flex ai-c jc-sb mb-1">
                <div class="flex ai-c text-light">
                  <CalendarAltIcon class="mr-1"/> Vistoria RFB
                </div>
              </div>
              <DatePicker v-model="process.datesInspectionOfTheDIDate" :disabled="disableClick" placeholder='' @input="() => inputUpdate('datesInspectionOfTheDIDate')"/>
            </div>

            <div class="span-2">
              <div class="flex ai-c jc-sb mb-1">
                <div class="flex ai-c text-light">
                  <CalendarAltIcon class="mr-1"/> Carregamento
                </div>
              </div>
              <DatePicker v-model="process.datesGoodsLoadingDate" disabled placeholder='N/I'/>
            </div>

            <div class="span-2">
              <div class="flex ai-c jc-sb mb-1">
                <div class="flex ai-c text-light">
                  <CalendarAltIcon class="mr-1"/> Entrega no Cliente
                </div>
              </div>
              <DatePicker v-model="process.datesCustomerDeliverDate" disabled placeholder='N/I'/>
            </div>
          </div>
        </div>

        <div class="span-6 medium-border-radius border-form gap2 mr-1 mb-1 ph-2 pb-2 pt-2">
          <div class="flex mt-1">
            <h6 class="text-bold text-center text-md mb-2"> MAPA</h6>
          </div>
          <div class="gap2 columns">
            <div class="span-4">
              <div class="flex ai-c jc-sb mb-1">
                <div class="flex ai-c text-light">
                  <CalendarAltIcon class="mr-1"/> Vistoria
                </div>
              </div>
              <DatePicker v-model="process.datesInspectionMAPADate" :disabled="disableClick" placeholder='' @input="() => inputUpdate('datesInspectionMAPADate')"/>
            </div>

            <div class="span-4">
              <div class="flex ai-c jc-sb mb-1">
                <div class="flex ai-c text-light">
                  <CalendarAltIcon class="mr-1"/> Liberação
                </div>
              </div>
              <DatePicker v-model="process.datesReleaseMAPADate" :disabled="disableClick" placeholder='' @input="() => inputUpdate('datesReleaseMAPADate')"/>
            </div>

            <div class="span-4">
              <div class="flex ai-c jc-sb mb-1">
                <div class="flex ai-c text-light">
                  <CalendarAltIcon class="mr-1"/> Vínculo
                </div>
              </div>
              <DatePicker v-model="process.datesLinkInTheEnclosureDate" :disabled="disableClick" placeholder='' @input="() => inputUpdate('datesLinkInTheEnclosureDate')"/>
            </div>
          </div>
        </div>

        <div class="span-6 medium-border-radius border-form gap2 mb-1 ph-2 pb-2 pt-2" style="grid-row: span 3;">
          <div class="flex mt-1">
            <h6 class="text-bold text-center text-md mb-2"> DECLARAÇÃO DE IMPORTAÇÃO</h6>
          </div>
          <div class="gap2 columns">
            <div class="span-4">
              <div class="flex ai-c jc-sb mb-1">
                <div class="flex ai-c text-light">
                  <CalendarAltIcon class="mr-1"/>
                  Data de Digitação
                </div>
              </div>
              <DatePicker v-model="process.diRegistryDate" :disabled="disableClick" placeholder='' @input="() => inputUpdate('diRegistryDate')"/>
            </div>

            <div class="span-4">
              <div class="flex ai-c jc-sb mb-1">
                <div class="flex ai-c text-light">
                  <CalendarAltIcon class="mr-1"/>
                  Data de Registro
                </div>
              </div>
              <DatePicker v-model="process.diRegistryDate" :disabled="disableClick" placeholder='' @input="() => inputUpdate('diRegistryDate')"/>
            </div>

            <div class="span-4">
              <div class="flex ai-c jc-sb mb-1">
                <div class="flex ai-c text-light">
                  <InfoIcon class="mr-1"/> Número da DI
                </div>
              </div>
              <input v-model="process.diNumber" v-mask="'##/#######-##'" :disabled="disableClick" type="text" @click="consultDi" @input="() => inputUpdate('diNumber')"/>
            </div>

            <div class="span-4">
              <div class="flex ai-c jc-sb mb-1">
                <div class="flex ai-c text-light">
                  <InfoIcon class="mr-1"/> Protocolo
                </div>
              </div>
              <input v-model="process.diProtocol" :disabled="disableClick" type="text" @input="() => inputUpdate('diProtocol')"/>
            </div>

            <div class="span-4">
              <div class="flex ai-c jc-sb mb-1">
                <div class="flex ai-c text-light">
                  <CalendarAltIcon class="mr-1"/>Data de Desembaraço
                </div>
              </div>
              <DatePicker v-model="process.diResourcefulnessDate" :disabled="disableClick" placeholder='' @input="() => inputUpdate('diResourcefulnessDate')"/>
            </div>

            <div class="span-4">
              <div class="flex ai-c jc-sb mb-1">
                <div class="flex ai-c text-light">
                  <InfoIcon class="mr-1"/> Canal
                </div>
              </div>
              <select v-model="process.diChannel" class="custom-select form-control wd-100" name="diChannel" @input="() => inputUpdate('diChannel')">
                <option value></option>
                <option value="VERDE">Verde</option>
                <option value="AMARELO">Amarelo</option>
                <option value="VERMELHO">Vermelho</option>
                <option value="CINZA">Cinza</option>
              </select>
            </div>

            <div class="span-12 mt-6">
              <div class="text-center">
                <DoubleCheckIcon class="mb-1" :class="process.diChannel === 'VERDE' ? 'text-success' : process.diChannel === 'AMARELO' ? 'text-warning' : process.diChannel === 'VERMELHO' ? 'text-danger' : process.diChannel === 'CINZA' ? '' : '' " size="3rem"/>
                <h6 class="text-bold text-light">DESEMBARAÇADO</h6>
                <p v-if="process.diChannel" :class="process.diChannel === 'VERDE' ? 'text-success' : process.diChannel === 'AMARELO' ? 'text-warning' : process.diChannel === 'VERMELHO' ? 'text-danger' : process.diChannel === 'CINZA' ? '' : '' "> Canal <strong>{{ process.diChannel }}</strong> </p>
                <p v-else>Nenhum canal informado</p>
              </div>
            </div>
          </div>
        </div>

        <div class="span-6 medium-border-radius border-form gap2 mr-1 mb-1 ph-2 pb-2 pt-2">
          <div class="flex mt-1">
            <h6 class="text-bold text-center text-md mb-2"> CONHECIMENTO</h6>
          </div>
          <div class="gap2 columns">
            <div class="span-3">
              <div class="flex ai-c jc-sb mb-1">
                <div class="flex ai-c text-light">
                  <InfoIcon class="mr-1"/> Situação Siscarga
                </div>
              </div>
              <Toggle v-model="process.datesSiscargaState" :disabled="disableClick" :labels="{ checked: 'LIBERADO', unchecked: 'PENDENTE', }" name="sit-siscarga" @input="() => inputUpdate('datesSiscargaState')"/>
            </div>

            <div class="span-3">
              <div class="flex ai-c jc-sb mb-1">
                <div class="flex ai-c text-light">
                  <CalendarAltIcon class="mr-1"/> Siscarga
                </div>
              </div>
              <DatePicker v-model="process.datesSiscargaDate" :disabled="disableClick" @input="() => inputUpdate('datesSiscargaDate')"/>
            </div>

            <div class="span-3">
              <div class="flex ai-c jc-sb mb-1">
                <div class="flex ai-c text-light">
                  <CalendarAltIcon class="mr-1"/> Liberação AWB / BL
                </div>
              </div>
              <DatePicker v-model="process.datesBillOfLadingReleaseDate" :disabled="disableClick" @input="() => inputUpdate('datesBillOfLadingReleaseDate')"/>
            </div>

            <div class="span-3">
                <div class="flex ai-c jc-sb mb-1">
                  <div class="flex ai-c text-light">
                    <InfoIcon class="mr-1"/> TFA
                  </div>
                </div>
                <Toggle v-model="process.datesTFASolved" :disabled="disableClick" :labels="{ checked: 'LIBERADO', unchecked: 'PENDENTE', }" name="tfa" @input="() => inputUpdate('datesTFASolved')" />
              </div>
            </div>
        </div>

        <div class="span-6 medium-border-radius border-form gap2 mr-1 mb-1 ph-2 pb-2 pt-2">
          <div class="flex mt-1">
            <h6 class="text-bold text-center text-md mb-2"> TRANSFERÊNCIA DE CARGA </h6>
          </div>
          <div class="gap2 columns">
            <div class="span-4" v-if="process.datesIsThereDTCDTATransfer">
              <div class="flex ai-c jc-sb mb-1">
                <div class="flex ai-c text-light">
                  <InfoIcon class="mr-1"/> DTC / DTA
                </div>
              </div>
              <DatePicker v-model="process.datesDTCDTA" :disabled="disableClick" placeholder='' @input="() => inputUpdate('datesInspectionOfTheDIDate')"/>
            </div>

            <div class="span-4" v-if="process.datesThereIsDesova">
              <div class="flex ai-c jc-sb mb-1">
                <div class="flex ai-c text-light">
                  <InfoIcon class="mr-1"/> Desova
                </div>
              </div>
              <DatePicker v-model="process.datesDesova" :disabled="disableClick" placeholder='' @input="() => inputUpdate('datesDesova')"/>
            </div>

            <div class="span-4">
              <div class="flex ai-c jc-sb mb-1">
                <div class="flex ai-c text-light">
                  <CalendarAltIcon class="mr-1"/> Fat Recinto
                </div>
              </div>
              <DatePicker v-model="process.datesEnclosureBillingDate" :disabled="disableClick" placeholder='' @input="() => inputUpdate('datesEnclosureBillingDate')"/>
            </div>

            <div class="span-4">
              <div class="flex ai-c jc-sb mb-1">
                <div class="flex ai-c text-light">
                  <CalendarAltIcon class="mr-1"/> Comex
                </div>
              </div>
              <DatePicker v-model="process.datesComexDate" :disabled="disableClick" placeholder='' @input="() => inputUpdate('datesComexDate')"/>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { mask } from 'vue-the-mask';
import moment from 'moment';
import api from '@/services/api';

import {
  BuildingIcon,
  InvoiceIcon,
  IdCardIcon,
  CalendarAltIcon,
  InfoIcon,
  DoubleCheckIcon,
  IndustryIcon,
  FileIcon,
  MapPinIcon,
  BoxesIcon,
} from '@/components/Icons';
import Toggle from '@/components/DataInput/Toggle.vue';
import DatePicker from '@/components/DataInput/DatePicker.vue';

export default {
  name: 'ModalProcessTabGeneral',
  directives: {
    mask,
  },
  components: {
    // Icons
    BuildingIcon,
    InvoiceIcon,
    IdCardIcon,
    CalendarAltIcon,
    InfoIcon,
    DoubleCheckIcon,
    IndustryIcon,
    FileIcon,
    MapPinIcon,
    BoxesIcon,
    // Componenets
    Toggle,
    DatePicker,
  },
  props: {
    process: {
      type: Object,
      default: () => {},
    },
    activeTab: {
      type: Number,
      default: 0,
    },
    disableClick: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      changed: false,
    };
  },
  methods: {
    formatData(data) {
      if (data) {
        return moment(data).format('DD/MM/YYYY');
      }

      return null;
    },
    ...mapActions(['toggleLoading']),
    ...mapActions('process', ['patchProcess']),
    async inputUpdate(field) {
      if (this.changed) {
        const data = {
          identification: this.process.identification,
          fieldUpdated: field,
          data: this.process,
        };
        await this.patchProcess(data);
      }
    },
    async consultDi() {
      if (this.process.diNumber.length === 13) {
        this.toggleLoading(true);
        const response = await api.get(
          `/integrations/myimp/consultarDi?processId=${this.process.id}&diIdentification=${this.process.diNumber}`,
        );

        if (response.data) {
          this.process.diRegistryDate = response.data.registryDate;
          this.process.diResourcefulnessDate = response.data.resourcefulnessDate;

          this.$toast.success('Dados encontrados com sucesso!');
        } else {
          this.$toast.error('Nenhuma informação encontrada');
        }

        this.toggleLoading(false);
      }
    },
  },
  updated() {
    this.changed = true;
  },
};
</script>

<style scoped>
.border-form {
  border: 1px solid #e2e2e2;
}
</style>
